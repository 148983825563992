import classNames from 'classnames';
import Image from 'next/image';
import { FC } from 'react';
import Colors from '~/constants/colors';
import Text, { TextProps } from '../Text';
import styles from './Divider.module.scss';

type DividerPosition =
    | 'center'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';

interface DividerProps {
    text?: string;
    subText?: string;
    subTextType?: TextProps['type'];
    subTextColor?: string;
    textProps?: Omit<TextProps, 'children'>;
    dividerColor?: string;
    className?: string;
    dividerClassName?: string;
    position?: DividerPosition;
    dividerType?: 'solid' | 'dashed';
}

const Divider: FC<DividerProps> = ({
    text,
    subText,
    dividerColor,
    className,
    textProps = {},
    dividerClassName,
    position = 'center',
    dividerType = 'solid',
    subTextType = 'body-2',
    subTextColor = Colors.GREY_700
}) => {
    const { type = 'title-3', color = Colors.GREY_100 } = textProps;

    if (!text) {
        if (dividerType === 'solid') {
            return <hr style={{ borderColor: dividerColor }} className={classNames(dividerClassName)} />;
        }

        return (
            <div className={classNames(dividerClassName, 'w-full h-[2px] relative')}>
                <Image src="/svgs/divider.svg" fill alt="divider" className="object-cover" />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.divider, styles[position], className, {
                'flex-col': position?.match(/(top|bottom)/)
            })}
        >
            <hr
                style={{ borderColor: dividerColor }}
                className={classNames('w-full', dividerClassName, {
                    // * hide this is text position is top or left
                    hidden: position?.match(/(top|left)/)
                })}
            />
            <div className="flex gap-3 items-center flex-shrink-0">
                <div
                    className={classNames(textProps.className, 'flex-shrink-0 text-xl lg:text-3xl font-semibold')}
                    color={color}
                >
                    {text}
                </div>
                {subText && (
                    <Text className="flex-shrink-0" type={subTextType} color={subTextColor}>
                        {subText}
                    </Text>
                )}
            </div>
            <hr
                style={{ borderColor: dividerColor }}
                className={classNames('w-full', dividerClassName, {
                    // * hide this is text position is bottom or right
                    hidden: position?.match(/(bottom|right)/)
                })}
            />
        </div>
    );
};

export default Divider;
